import React, { useEffect, useState } from 'react';
import pcStyles from './App.module.scss';
import mobileStyles from './mobile.module.scss';
import padStyles from './pad.module.scss';
import { useConnect, useAccount } from 'wagmi';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { useConnectModal } from '@rainbow-me/rainbowkit'

function App() {
  const [styles, setStyles] = useState(pcStyles)
  const { address, isConnected } = useAccount();
  const { openConnectModal, connectModalOpen } = useConnectModal()
  const { connect, connectors, error, isLoading } = useConnect({
    connector: new InjectedConnector(),
    onMutate(connector) {
      console.log('Before Connect', isLoading, connector, error, connectors, connect, connectModalOpen, address)
    },
    onSuccess(data) {
      console.log('Connect', data)
    },
    onSettled(data, error) {
      console.log('Settled', { data, error })
    },
    onError(error) {
      console.log('Error', error)
    }
  })

  const handleMint = async () => {
    // 连接钱包
    alert("Mint has not started! Stay tuned by joining our telegram group and following our twitter!")
    // if (openConnectModal) {
    //   if (!isConnected) {
    //     openConnectModal()
    //     // console.log(openConnectModal, 'openConnectModal')
    //   }
    // } else {
    //   alert("MetaMask is not installed!");
    // }
  }

  // 浏览器适配
  const handleStyleFormat = () => {
    const clientWidth = document.documentElement.clientWidth
    if (clientWidth < 1440) {
      if (clientWidth > 430) {
        document.documentElement.style.fontSize = clientWidth * 100 / 834 + 'px'
        setStyles(padStyles)
      } else {
        document.documentElement.style.fontSize = clientWidth * 100 / 430 + 'px'
        setStyles(mobileStyles)
      }
    } else {
      setStyles(pcStyles)
    }
  }

  useEffect(() => {
    handleStyleFormat()
    window.addEventListener('resize', () => {
      handleStyleFormat()
    })
  }, [])

  return (
    <div className={styles["App"]}>
      <div className={styles["block-center"]}>
        <div className={`${styles["logo"]} ${styles["logo-1"]}`}></div>
        <div className={`${styles["logo"]} ${styles["logo-2"]}`}></div>
        <div className={`${styles["logo"]} ${styles["logo-3"]}`}></div>
        <div className={styles["title"]}>Memeworld</div>
        <div className={`${styles["logo"]} ${styles["logo-4"]}`}></div>
        <div className={`${styles["logo"]} ${styles["logo-5"]}`}></div>
        <div className={`${styles["link-style"]} ${styles["link-mint"]}`} onClick={handleMint}><div className={styles["link-icon"]}></div>MINT</div>
        <a rel="noreferrer" href='https://twitter.com/Memeworld_tweet' target='_blank' className={`${styles["link-style"]} ${styles["link-x"]}`}><div className={styles["link-icon"]}></div>X</a>
        <a rel="noreferrer" href='https://t.me/+r_B0hD3g7K0yNmJl' target='_blank' className={`${styles["link-style"]} ${styles["link-discord"]}`}><div className={styles["link-icon"]}></div>TELEGRAM</a>
        <div className={styles["year"]}>- 2023 -</div>
        <div className={styles["content-box"]}>
          <div className={styles["content-item"]}>
            <div className={styles["item-title"]}>Comeback of Crypto</div>
            <div className={styles["item-text"]}>The year of 2023 has witnessed an impressive comeback of the crypto market, underlining the resilience, strength and dedication of its community. The meteoric recovery of the crypto market is attributed to a collective effort by developers and supporters who doubled down on their commitment to the underlying technology and potentials along with meme token community which has infused a fresh and playful energy into the crypto narrative. </div>
          </div>
          <div className={styles["content-item"]}>
            <div className={styles["item-title"]}>Rise of Meme Culture</div>
            <div className={styles["item-text"]}>Meme tokens, a unique class of cryptocurrencies, have garnered a passionate following in the digital asset community, thriving on a blend of humor, pop culture, and social media virality. Recently, we have seen some meme tokens like Bonk, Dogwifhat, Gecko, Silly Dragon gain significant momentum in a short period of time. These playful meme tokens could very well become a catalyst for new, creative initiatives and community engagement.</div>
          </div>
          <div className={styles["content-item"]}>
            <div className={styles["item-title"]}>Birth of Generative AI</div>
            <div className={styles["item-text"]}>Generative AI represents a fascinating frontier in the field of artificial intelligence, where machines are not just learning, but creating. With the release of chatGPT at the end of last year, we have experienced a year of hyper growth in the popularity and utility of Generative AI technologies, which have changed forever the ways how we work, create content and interact with each other.</div>
          </div>
          <div className={styles["link-container"]}>
            <a rel="noreferrer" href='https://twitter.com/Memeworld_tweet' target='_blank' className={`${styles["link-style"]} ${styles["link-x"]}`}><div className={styles["link-icon"]}></div>X</a>
            <a rel="noreferrer" href='https://t.me/+r_B0hD3g7K0yNmJl' target='_blank' className={`${styles["link-style"]} ${styles["link-discord"]}`}><div className={styles["link-icon"]}></div>TELEGRAM</a>
          </div>
          <div className={styles["footer-text"]}>
            MEMEWORLD IS A MEME COIN. THIS IS NOT INVESTMENT ADVICE. THE COIN IS FOR ENTERTAINMENT AND EDUCATIONAL PURPOSES ONLY. THE FOUNDERS ARE NOT LIABLE FOR ANY LOSSES OR DAMAGES. THE MARKET IS VOLATILE; INVEST AT YOUR OWN RISK. NO GUARANTEES OF PROFIT OR VALUE RETENTION. RESEARCH THOROUGHLY BEFORE INVESTING. BY BUYING, YOU ACKNOWLEDGE THE RISKS INVOLVED. FOUNDERS HAVE NO OBLIGATION TO UPDATE INFORMATION. LAWS VARY BY JURISDICTION; COMPLY WITH LOCAL REGULATIONS. THIS DISCLAIMER IS SUBJECT TO CHANGES WITHOUT NOTICE.
              <br />
            <br />
            COPYRIGHT © 2023 ALL RIGHTS RESERVED MEMEWORLD
          </div>
        </div>
      </div>
      <div className={styles["bg-container"]}>
        {
          new Array(2).fill(0).map((e, index) =>
            <div key={`bg-${index}`}>
              <div className={styles["block"]}>
                <div className={styles["block-bg"]}></div>
              </div>
              <div className={styles["block"]}>
                <div className={`${styles["block-bg"]} ${styles["block-bg-reverse"]}`}></div>
              </div>
            </div>
          )
        }
      </div>
    </div >
  );
}

export default App;
